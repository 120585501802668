import { humanize } from "utils/common-function";
export const getColumPlanHistory = () => {
  const columns = [
    {
      dataField: "EXECUTION_P_ID",
      text: "Plan Execution Id",
      sort: true,
    },
    {
      dataField: "STATUS_DATETIME",
      text: "Status DateTime",
      sort: true,
    },
    {
      dataField: "STATUS_TEXT",
      text: "Plan Status",
      sort: true,
    },
  ];
  return columns;
};
