import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import image from "assets/banner.jpg";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
  banner: {
    top: 0,
    width: "100%",
  },
  container: {
    display: "flex",
    maxWidth: "100%",
    justifyContent: "center",
    alignContent: "center",
    padding: 0,
  },
  img: {
    width: "100%",
    height: "100px",
  },
  desktopimg: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  mbimg: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  screenname:{
    position: "absolute",
    top: 80,
    left: 20,
    color:"#fff",
    fontSize: "1.3rem",
    fontWeight:"bold"
  },
}));
export const Banner = (props) => {
  const {ScreenName} = props
  const classes = useStyles();
  return (
    <>
      <section name="banner" className={classes.banner}>
        <Box display="flex" justifyContent="center">
          <Box style={{ width : "100%"}}>
          <Typography className={classes.screenname}>{ScreenName}</Typography>
            <img
            alt=""
              src={image}
              className={`${classes.img} ${classes.desktopimg}`}
            />
            <img
             alt=""
              src={image}
              className={`${classes.img} ${classes.mbimg}`}
            />
          </Box>
        </Box>
      </section>
    </>
  );
};
export default Banner;
