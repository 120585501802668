import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "./../../assets/logo.png";
import "./appbar.css";
//Material
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import PersonIcon from "@material-ui/icons/Person";
import MenuIcon from "@material-ui/icons/Menu";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";
import { Divider } from "@material-ui/core";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Badge from "@material-ui/core/Badge";
//Function
import { Logout } from "utils/sessions";
import { API_URL } from "utils/api-utils";
import { isEmptyStr } from "utils/common-function";
//Context
import { MenuContext } from "../../apps/main/portal/menu-context";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  appbar: {
    backgroundColor: "#594F74",
    color: "#fff",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    //justifyContent: "center",
    //alignSelf: "center",
  },
  grow: {
    flexGrow: 1,
  },
  logo: {},
  name: {},
  icon: {
    marginLeft: theme.spacing(1),
    //marginBottom:theme.spacing(1)
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export const AppbarWithMenu = ({
  children,
  user,
  ScreenName,
  ScreenList = [],
  helper,
  ...props
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const context = useContext(MenuContext);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const location = useLocation();
  const apps = location.pathname.split("/")[1];
  const handleClose = () => {
    setAnchorEl(null);
  };
  //LoadHelper
  useEffect(() => {
    context.actions.GetHelperList();
  }, [context.actions]);
  //Find Helper Link
  let link = context.actions.getHelperLink(helper,context.state.HelperList);
  link = link && `${API_URL.Docs}${link}`;
  //arrange menu name
  const Screenlst = ScreenList?.find(el=>el.APPLICATION_NAME === apps)?.ModuleGroups?.map((grp) => (
    <div key={grp.Group}>
      <Divider />
      <Box p={1}>
        <Typography p={1} variant="caption">
          {grp.Group}
        </Typography>
      </Box>

      {grp?.Modules.map((el) => (
        <MenuItem
          key={el.ID}
          onClick={handleClose}
          component={RouterLink}
          to={el.path || "/"}
        >
          {el.MODULE_NAME || ""}
        </MenuItem>
      ))}
    </div>
  ));
 

  return (
    <React.Fragment>
      <header>
        <AppBar className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
           
            {/* <Typography>{ScreenName}</Typography> */}
            {/* <img src={logo} height="75" alt="" /> */}
            <div className={classes.grow} />
            <Box display={"flex"} flexDirection={"column"} ml={3} alignItems={"flex-end"}>
              <Box>
              <Typography>{user}</Typography>
              </Box>
              {/* <Box >
              <Typography style={{fontSize:"0.7rem"}}> {user}</Typography>
              </Box> */}
            </Box>
            <IconButton onClick={handleClick}  style={{ color: "#fff" }}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                key="home"
                onClick={handleClose}
                component={RouterLink}
                to={"/"}
              >
                Home
              </MenuItem>
              <MenuItem
                key="logout"
                onClick={() => {
                  handleClose();
                  Logout();
                }}
              >
                Logout
              </MenuItem>

              {Screenlst}
            </Menu>
            {/* <Badge badgeContent={4} overlap="rectangular">
              <NotificationsNoneIcon />
            </Badge> */}
            {/* {user} */}
         
            
            {/* <PersonIcon className={classes.icon} /> */}
            {isEmptyStr(link) ? null : (
              <HelpOutlineIcon
                className={classes.icon}
                onClick={() => window.open(link)}
              />
            )}
          </Toolbar>
        </AppBar>
        <Toolbar id="back-to-top-anchor" />
      </header>
      {children}
      <section name="goTop">
        <ScrollTop {...props}>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </section>
    </React.Fragment>
  );
};
AppbarWithMenu.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  user: PropTypes.string,
};
export default AppbarWithMenu;
