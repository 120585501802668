import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import axios from "axios";
import moment from "moment";
import { common } from "./../../utils";
import { API_URL } from "./../../utils/api-utils";
//Manipulate with ExecutionPlanList
export const getExecutionPlanList = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetExecutionPlanList`;

    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const GetPlanDetail = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetPlanDetail`;

    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const DeleteExecutionPlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/DeleteExecutionPlan`;
    let { result, error } = await common.to(axios.post(url, param));
    if (error) return false
    if (result && result.data) return result.data;
};
export const GetExecutionPlanHistoryList = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetExecutionPlanHistoryList`;
    let { result, error } = await common.to(axios.post(url, param));
    if (error) return false
    if (result && result.data) return result.data;
};
export const SetInvalidPlanInstance = async (param) => {
    let url = `${API_URL.Job}/api/execution/SetInvalidPlanInstance`;
    let { result, error } = await common.to(axios.post(url, param));
    if (error) return false
    if (result && result.data) return result.data;
};
export const DeployPlanPreview = async (param) => {
    let url = `${API_URL.Job}/api/execution/DeployPlanPreview`;
    let { result, error } = await common.to(axios.post(url, param));
   
    if (result && result.data) return result.data;
};
export const GetLogDeployPlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetLogDeployPlan`;
    let { result, error } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const CancelDeployPlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/CancelDeployPlan`;
    let { result, error } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const DeployPlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/DeployPlan`;
    let { result, error } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
//GetExecutionDetail
export const CreateExecutionPlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/CreateExecutionPlanForEdit`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const GetExecutionPlanDetail = async (param) => {
    let url = `${API_URL.Job}/api/execution/LoadExecutionPlanForEdit`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const CheckCurrentEditedPlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/CheckCurrentEditedPlan`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const GetCurrentEditedPlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetCurrentEditedPlan`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const LoadPlanForExecute = async (param) => {
    let url = `${API_URL.Job}/api/execution/LoadPlanForExecute`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const ExecutePlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/ExecutePlan`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
//Main Operation with SBX
export const CancelEditExecutionPlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/CancelEditExecutionPlan`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const ValidateSubmitExecutionPlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/ValidateSubmitExecutionPlan`;
    return  await common.to(axios.post(url, param));
    
};
export const SubmitExecutionPlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/SubmitExecutionPlan`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
//Manipulate with Detail
export const SavePlanNameSbx = async (param) => {
    let url = `${API_URL.Job}/api/execution/SavePlanNameSbx`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const SavePlanScheduleSbx = async (param) => {
    let url = `${API_URL.Job}/api/execution/SavePlanScheduleSbx`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
//Manipulate with JobList
export const GetSBXPlanJobDetailList = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetSBXPlanJobDetailList`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const AddJobToPlanSbx = async (param) => {
    let url = `${API_URL.Job}/api/execution/AddJobToPlanSbx`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const UpdateJobSequenceSbx = async (param) => {
    let url = `${API_URL.Job}/api/execution/UpdateJobSequenceSbx`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const EditJobDetail = async (param) => {
    let url = `${API_URL.Job}/api/execution/SavePlanJobDetailSbx`;
    await common.to(axios.post(url, param));
};
export const DeleteJob = async (param) => {
    let url = `${API_URL.Job}/api/execution/RemoveJobFromPlanSbx`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
//Manipulate with Execution Dependency plan
export const GetDependencyOfThisPlanSbx = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetDependencyOfThisPlanSbx`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const GetDependencyChainOfThisPlanDisplay = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetDependencyChainOfThisPlanDisplay`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const AddDependencyOfThisPlanSbx = async (param) => {
    let url = `${API_URL.Job}/api/execution/AddDependencyOfThisPlanSbx`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const UpdateDepencyDetail = async (param) => {

    let url = `${API_URL.Job}/api/execution/SaveDataDateTypeOfPlan`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const RemoveDependency = async (param) => {
    let url = `${API_URL.Job}/api/execution/DeleteDependencyOfThisPlanSbx`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
//Reprocess
export const GetReprocessPlanList = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetReprocessPlanList`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const GetReprocessPlanDetailList = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetReprocessPlanDetailList`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const EditReprocess = async (param) => {
    let url = `${API_URL.Job}/api/execution/EditReprocess`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
//EditReprocess
export const SaveReprocessHeader = async (param) => {
    let url = `${API_URL.Job}/api/execution/SaveReprocessHeader`;
    let { error } = await common.to(axios.post(url, param));
    return error;
};
export const SaveReprocessDetail = async (param) => {
    let url = `${API_URL.Job}/api/execution/SaveReprocessDetail`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const SaveScheduleReprocess = async (param) => {
    let url = `${API_URL.Job}/api/execution/SaveScheduleReprocess`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const ValidateSubmitReprocess = async (param) => {
    let url = `${API_URL.Job}/api/execution/ValidateSubmitReprocess`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const SubmitReprocess = async (param) => {
    let url = `${API_URL.Job}/api/execution/SubmitReprocess`;
    let { error } = await common.to(axios.post(url, param));
    return error;
};
export const DeleteReprocess = async (param) => {
    let url = `${API_URL.Job}/api/execution/DeleteReprocess`;
    let { error } = await common.to(axios.post(url, param));
    return error;
};
export const CancelReprocess = async (param) => {
    let url = `${API_URL.Job}/api/execution/CancelReprocess`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const CheckCurrentEditReprocess = async (param) => {
    let url = `${API_URL.Job}/api/execution/CheckCurrentEditReprocess`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
//DailyPlan
export const GetDailyPlanList = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetDailyPlanList`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const GetPlanReprocessHistory = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetPlanReprocessHistory`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const HoldPlans = async (param) => {
    let url = `${API_URL.Job}/api/execution/HoldPlans`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const InvalidatePlans = async (param) => {
    let url = `${API_URL.Job}/api/execution/InvalidatePlans`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const SuccessPlans = async (param) => {
    let url = `${API_URL.Job}/api/execution/SuccessPlans`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const CancelRunningPlans = async (param) => {
    let url = `${API_URL.Job}/api/execution/CancelRunningPlans`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const BypassDependency = async (param) => {
    let url = `${API_URL.Job}/api/execution/BypassDependency`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const CreateReprocess = async (param) => {
    let url = `${API_URL.Job}/api/execution/CreateReprocess`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const GetReprocessImpactedPlans = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetReprocessImpactedPlans`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const GetReprocessDependencyPlans = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetReprocessDependencyPlans`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const SaveReprocessDependencyIgnore = async (param) => {
    let url = `${API_URL.Job}/api/execution/SaveReprocessDependencyIgnore`;
    let { error } = await common.to(axios.post(url, param));
     return error;
};
export const GetImpactPlan = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetImpactedPlans`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
// Job
export const GetJobHistory = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetJobHistory`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const GetPlanHistory = async (param) => {
    let url = `${API_URL.Job}/api/execution/GetPlanHistory`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
export const GetLogTalend = async (param) => {
    let url = `${API_URL.Job}/api/JobEdge/GetLogTalend`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
};
//combo

export const GetBackTo = () => {
    const data = [
        { id: 1, label: "1 Day" },
        { id: 2, label: "3 Days" },
        { id: 3, label: "1 Week" },
        { id: 4, label: "2 Weeks" },
        { id: 5, label: "1 Month" },
        { id: 6, label: "3 Months" },
        { id: 7, label: "6 Months" },
        { id: 8, label: "1 Year" },
    ];
    return data;
};
export const GenerateFormDate = (id, todate) => {
    let fromdate = moment(todate);
    if (!fromdate.isValid()) return "";
    switch (id) {
        case 1:
            //fromdate = fromdate.subtract(1 - 1, "days");
            fromdate = fromdate.subtract(0, "days");
            break;
        case 2:
            fromdate = fromdate.subtract(3 - 1, "days");
            break;
        case 3:
            fromdate = fromdate.subtract(1, "weeks");
            break;
        case 4:
            fromdate = fromdate.subtract(2, "weeks");
            break;
        case 5:
            fromdate = fromdate.subtract(1, "months");
            break;
        case 6:
            fromdate = fromdate.subtract(3, "months");
            break;
        case 7:
            fromdate = fromdate.subtract(6, "months");
            break;
        case 8:
            fromdate = fromdate.subtract(1, "years");
            break;
        default:
            break;
    }
    return fromdate.format("YYYY-MM-DD");
};
// export const SetDateOrTimeForSchedule = (type = 1, datetime_api) => {
//   if (!datetime_api) return;
//   let result = "";
//   if (type === 2) {
//     result = moment(datetime_api).format("YYYY-MM-DDTHH:mm");
//   } else {
//     result = moment(datetime_api).format("HH:mm");
//   }
//   if (result == "Invalid date") return "";
//   return result;
// };
