import "./App.css";
import { useEffect,useContext } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
//Component
import { Login } from "./apps/main/login";
import Test from "./apps/main/example/test-checkbox";
import { ProtectedRoutes } from "./template/ProtectedRoutes";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import HandlePaperTicket from "./module/paperticket/paperticket";
//Context
import { CaptionFieldContext } from "apps/data-catalog/caption-field-context/caption-field-context";
//utils
import { common } from "utils";
import { API_URL } from "utils/api-utils";

const GetFieldsDictionary = async (param) => {
  let url = `${API_URL.Report}/api/Master/GetFieldsDictionary`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: [
        'Sarabun-Regular',
      ].join(','),
    },
  });
  const context = useContext(CaptionFieldContext);
  const { state, actions } = context;
  useEffect(() => {
    (async () => {
      let [data, err] = await GetFieldsDictionary();
      actions.SetFieldCaption(data);
    })();
  }, [actions]);
  return (
    <>
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Route exact path="/Login" component={Login} />
        <Route exact path="/Test" component={Test} />
        {/* <RouteLayout exact path="/" component={Portal} /> */}
        <Route exact path="/register" component={HandlePaperTicket} />
        <Route exact path={ProtectedRoutes.route} component={ProtectedRoutes} />
      </Router>
    </ThemeProvider>
    </>
  );
}
export  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }
export default App;
